import {
  Box,
  Button,
  Divider,
  Grid,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  gerarAssessmentFormData,
  gerarContentFormData,
  gerarElementoSelecionavel,
  gerarExamFormData,
  gerarItemFormData,
  gerarTaskFormData,
  gerarTaskSubcontentFormData,
} from "../utils/elementBuilders";

import { ReactComponent as Lixeira } from "assets/editiNavHeadImages/Principal-7.svg";

import { ReactComponent as Pencil } from "assets/editiNavHeadImages/pencil.svg";
import { ReactComponent as Duplicate } from "assets/editiNavHeadImages/duplicate.svg";
import { ReactComponent as Duplicate_DISABLED } from "assets/editiNavHeadImages/duplicate_disabled.svg";
import { ReactComponent as Up } from "assets/editiNavHeadImages/UP.svg";
import { ReactComponent as Down } from "assets/editiNavHeadImages/DOWN.svg";
import { ReactComponent as Up_ENABLED } from "assets/editiNavHeadImages/UP_ENABLED.svg";
import { ReactComponent as Down_ENABLED } from "assets/editiNavHeadImages/DOWN_ENABLED.svg";

import { ReactComponent as Avaliacao } from "assets/editiNavHeadImages/contextButtomMenu/avaliacao.svg";
import { ReactComponent as Exame } from "assets/editiNavHeadImages/contextButtomMenu/exame.svg";
import { ReactComponent as Item } from "assets/editiNavHeadImages/contextButtomMenu/item.svg";
import { ReactComponent as Subenunciado } from "assets/editiNavHeadImages/contextButtomMenu/subenunciado.svg";
import { ReactComponent as Tarefa } from "assets/editiNavHeadImages/contextButtomMenu/tarefa.svg";

import { ReactComponent as Aula } from "assets/editiNavHeadImages/contextButtomMenu/aula_ativo.svg";
import { ReactComponent as Conteudo } from "assets/editiNavHeadImages/contextButtomMenu/conteudo_ativo.svg";
import { ReactComponent as TarefaAtivo } from "assets/editiNavHeadImages/contextButtomMenu/tarefa_ativo.svg";
import { ReactComponent as ItemAtivo } from "assets/editiNavHeadImages/contextButtomMenu/item_ativo.svg";

import {
  Content,
  ElementTypes,
  ExamFormData,
  IReorderItemRequest,
  IReorderRequest,
  Item as ItemType,
  SelectedElement,
  Task,
  TaskSubContent,
} from "services/admin/Interfaces/Types";
import { setSelectedElement } from "store/reducers/Admin/Actions/treeView/actions";
import EditNavHeadButton from "./EditNavHeadButton";
import { useEffect, useState } from "react";
import { KeyboardArrowDown } from "@mui/icons-material";
import ContextualMenuListItem from "./ContextualMenuListItem";
import { padding } from "@mui/system";
import { moveTaskInsideExam } from "store/reducers/Admin/AsyncActions/Tasks";
import { getTaskById } from "../utils/elementGetters";
import { getAllTasks } from "store/reducers/Admin/Actions/Tasks/actions";
import { moveItemInsideTask } from "store/reducers/Admin/AsyncActions/Itens";
import {
  reorderItemInsideTask,
  reorderSubContentInsideTask,
} from "../utils/treeUtils";
import { processContentUpdate } from "store/reducers/Admin/AsyncActions/content";
import {
  updateSubContent,
  updateSubContentBulk,
} from "store/reducers/task_subcontent/AsyncActions";
import TaskSubContentService from "store/reducers/task_subcontent/AsyncActions/taskSubcontent.services";

interface IEditNavHead {
  onClickDelete: any;
}

export default function EditNavHead({ onClickDelete }: IEditNavHead) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [reorderElements, setReorderElements] = useState<
    IReorderRequest | undefined
  >();

  const [subcontentReorder, setSubcontentReorder] = useState<
    TaskSubContent[] | undefined
  >();

  const tasks: Task[] = useSelector((state: any) => state.admin).tasks;

  useEffect(() => {
    const saveTaskOrder = setTimeout(() => {
      if (reorderElements) {
        dispatch(moveItemInsideTask(reorderElements));
        setReorderElements(undefined);
      }
    }, 4000);
    return () => clearTimeout(saveTaskOrder);
  }, [reorderElements]);

  useEffect(() => {
    const saveSubcontentOrder = setTimeout(() => {
      if (subcontentReorder) {
        dispatch(updateSubContentBulk(subcontentReorder as TaskSubContent[]));
        setSubcontentReorder(undefined);
      }
    }, 4000);
    return () => clearTimeout(saveSubcontentOrder);
  }, [subcontentReorder]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectedElement: SelectedElement = useSelector(
    (state: any) => state.admin
  ).selectedElement;

  const hasSelectedElement = () => {
    return (
      selectedElement.elementType !== undefined &&
      selectedElement.element !== undefined
    );
  };

  const checkSelectedElementType = (checkType: string) => {
    return selectedElement.elementType === checkType;
  };

  const initDataFormCreationTask = () => {
    handleClose();
    dispatch(
      setSelectedElement(
        gerarElementoSelecionavel(
          ElementTypes.TASK,
          undefined,
          gerarTaskFormData(undefined),
          selectedElement.element?.id
        )
      )
    );
  };

  const initDataFormCreationItem = () => {
    handleClose();
    dispatch(
      setSelectedElement(
        gerarElementoSelecionavel(
          ElementTypes.ITEM,
          undefined,
          gerarItemFormData(undefined, selectedElement.element as Task),
          undefined
        )
      )
    );
  };

  const initDataFormCreationSubcontent = () => {
    handleClose();
    dispatch(
      setSelectedElement(
        gerarElementoSelecionavel(
          ElementTypes.TASK_SUBCONTENT,
          undefined,
          gerarTaskSubcontentFormData(
            undefined,
            selectedElement.element as Task
          ),
          undefined
        )
      )
    );
  };

  const initDataFormCreationExam = () => {
    handleClose();
    dispatch(
      setSelectedElement(
        gerarElementoSelecionavel(
          ElementTypes.EXAM,
          undefined,
          gerarExamFormData(undefined, undefined),
          selectedElement.element?.id
        )
      )
    );
  };

  const initDataFormCreationAssessment = () => {
    handleClose();
    dispatch(
      setSelectedElement(
        gerarElementoSelecionavel(
          ElementTypes.ASSESSMENT,
          undefined,
          gerarAssessmentFormData(undefined),
          undefined
        )
      )
    );
  };

  const moveSelectedElement = (direction: "UP" | "DOWN") => {
    if (hasSelectedElement() && checkSelectedElementType(ElementTypes.TASK)) {
      dispatch(
        moveTaskInsideExam(
          selectedElement.element as Task,
          selectedElement.parentElement as number,
          direction
        )
      );
    }

    if (hasSelectedElement() && checkSelectedElementType(ElementTypes.ITEM)) {
      let task: Task = getTaskById(
        tasks,
        (selectedElement.element as ItemType).task as number
      ) as Task;

      task = reorderItemInsideTask(
        task,
        (selectedElement.element as ItemType).id as number,
        direction
      );

      const reorderRequest: IReorderRequest = {
        items: task.items.map(
          (item) =>
            ({
              id: item.id as number,
              number: item.number,
            } as IReorderItemRequest)
        ),
      };
      setReorderElements(reorderRequest);
      refreshTaskList(task);
    }

    if (
      hasSelectedElement() &&
      checkSelectedElementType(ElementTypes.TASK_SUBCONTENT)
    ) {
      let task: Task = getTaskById(
        tasks,
        (selectedElement.element as ItemType).task as number
      ) as Task;

      task = reorderSubContentInsideTask(
        task,
        (selectedElement.element as TaskSubContent).id as number,
        direction
      );

      setSubcontentReorder(task.subcontents);
      refreshTaskList(task);
    }
  };

  const refreshTaskList = (task: Task) => {
    let copyTasks: Task[] = [...tasks];
    let indexTask: number = -1;
    copyTasks.forEach((entry, index) => {
      if (entry.id === task.id) indexTask = index;
    });

    copyTasks[indexTask as number] = task;
    dispatch(getAllTasks(copyTasks));
  };

  return (
    <Grid
      container
      spacing={2}
      paddingLeft={2}
      paddingRight={3}
      paddingBottom={0}
    >
      <Grid item xs={5} gap={"12px"}>
        <Box
          sx={{
            paddingLeft: 1,
            paddingTop: 1,
          }}
        >
          <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDown />}
            sx={{ textTransform: "none", width: "100%" }}
          >
            Inserir
          </Button>
        </Box>

        <Menu
          elevation={0}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          sx={{
            color: "var(--primary-regular)",
            "& .MuiPaper-root": {
              border: "1px solid var(--neutral-3)",
              borderRadius: "8px",
            },
          }}
        >
          <>
            <ContextualMenuListItem
              text="Avaliação"
              icon={<Avaliacao />}
              action={initDataFormCreationAssessment}
              redirectUrl="/editor/create/assessment"
            />
            <ContextualMenuListItem
              text="Exame"
              icon={<Exame />}
              disabled={
                !(
                  hasSelectedElement() &&
                  checkSelectedElementType(ElementTypes.ASSESSMENT)
                )
              }
            />
            <ContextualMenuListItem
              text="Novo Exame"
              action={initDataFormCreationExam}
              redirectUrl="/editor/create/exam"
              disabled={
                !(
                  hasSelectedElement() &&
                  checkSelectedElementType(ElementTypes.ASSESSMENT)
                )
              }
            />
            <ContextualMenuListItem
              text="Carregar Exame"
              action={initDataFormCreationExam}
              redirectUrl="/editor/load/exam"
              disabled={
                !(
                  hasSelectedElement() &&
                  checkSelectedElementType(ElementTypes.ASSESSMENT)
                )
              }
            />
            <ContextualMenuListItem
              text="Tarefa"
              icon={<Tarefa />}
              disabled={
                !(
                  hasSelectedElement() &&
                  checkSelectedElementType(ElementTypes.EXAM)
                )
              }
            />
            <ContextualMenuListItem
              text="Nova Tarefa"
              action={initDataFormCreationTask}
              redirectUrl="/editor/create/task"
              disabled={
                !(
                  hasSelectedElement() &&
                  checkSelectedElementType(ElementTypes.EXAM)
                )
              }
            />
            <ContextualMenuListItem
              text="Carregar Tarefa"
              disabled={
                !(
                  hasSelectedElement() &&
                  checkSelectedElementType(ElementTypes.EXAM)
                )
              }
              redirectUrl="/editor/load/task"
            />

            <ContextualMenuListItem
              text="Item"
              icon={<Item />}
              action={initDataFormCreationItem}
              redirectUrl="/editor/create/item"
              disabled={
                !(hasSelectedElement() && checkSelectedElementType("TASK"))
              }
            />

            <ContextualMenuListItem
              text="Sub-Enunciado"
              icon={<Subenunciado />}
              action={initDataFormCreationSubcontent}
              redirectUrl="/editor/create/subcontent"
              disabled={
                !(hasSelectedElement() && checkSelectedElementType("TASK"))
              }
            />
          </>
        </Menu>
      </Grid>

      <Grid item xs={1.5}>
        <EditNavHeadButton
          active={false}
          svgImage={<Duplicate_DISABLED />}
          toolTipText="Duplicar Elemento"
        />
      </Grid>

      <Grid item xs={1.5}>
        <EditNavHeadButton
          active={enableOrderButtom(selectedElement)}
          svgImage={
            enableOrderButtom(selectedElement) ? <Up_ENABLED /> : <Up />
          }
          toolTipText="Mover Elemento"
          redirectPath="/editor"
          action={() => moveSelectedElement("UP")}
        />
      </Grid>

      <Grid item xs={1.5}>
        <EditNavHeadButton
          active={enableOrderButtom(selectedElement)}
          svgImage={
            enableOrderButtom(selectedElement) ? <Down_ENABLED /> : <Down />
          }
          toolTipText="Mover Elemento"
          redirectPath="/editor"
          action={() => moveSelectedElement("DOWN")}
        />
      </Grid>

      <Grid item xs={1.5}>
        <EditNavHeadButton
          active={hasSelectedElement()}
          redirectPath={"/editor/"}
          action={onClickDelete}
          svgImage={<Lixeira />}
          toolTipText="Remover Elemento"
        />
      </Grid>
    </Grid>
  );
}

function enableOrderButtom(selectedElement): boolean {
  return (
    selectedElement.element !== undefined &&
    selectedElement.element.id !== undefined &&
    (selectedElement.elementType === ElementTypes.TASK ||
      selectedElement.elementType === ElementTypes.ITEM ||
      selectedElement.elementType === ElementTypes.TASK_SUBCONTENT)
  );
}
