import {
  Alert,
  AlertColor,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Delete } from "@mui/icons-material";

import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  Ability,
  AbilityEdge,
  Knowledge,
  Page,
} from "services/admin/Interfaces/Types";
import abilities from "store/reducers/abilities";
import AbilitiesService from "store/reducers/abilities/AsyncActions/abilities.service";
import {
  createAbilityEdge,
  deleteAbilityEdge,
  getAbilityEdge,
} from "store/reducers/Ability_Edge/AsyncActions";
import AbilityEdgeService from "store/reducers/Ability_Edge/AsyncActions/ability_edge.service";
import {
  AbilitySelector,
  onSelectAbilityEvent,
} from "./components/AbilitySelector";
import KnowledgeService from "store/reducers/knowledges/AsyncActions/knowledge.service";
import KnowledegesServices from "store/reducers/Admin/AsyncActions/KnowledgesArea/knowledges.services";
import Graph from "components/Graph";
import { Box } from "@mui/system";
import AbilityRelationDialog from "components/Admin/dialogComponents/AbilityRelationDialog";
import { Exception } from "sass";
import CardBase from "components/Admin/forms/CardBase";

interface SnackbarData {
  type: AlertColor;
  open: boolean;
  message: string;
}

const defaultSnackbar: SnackbarData = {
  message: "",
  open: false,
  type: "success",
};

export const AbilityRelationship = () => {
  const [data, setData] = useState<object>({});
  const [clearFunctions, setClearFunctions] = useState<(() => void)[]>([]);
  const [snackBar, setSnackBar] = useState<SnackbarData>(defaultSnackbar);
  const [page, setPage] = useState(0);
  const [knowledges, setKnowledges] = useState();
  const [abilitiesDataGraph, setAbilitiesDataGraph] = useState();
  const [openModal, setOpenModal] = useState(false);

  const [selectedRelation, setSelectedRelation] = useState(
    undefined as any | undefined
  );

  const [abilitiesDataGraphFiltered, setAbilitiesDataGraphFiltered] =
    useState<Page<AbilityEdge>>();

  const pageAbilities: Page<AbilityEdge> = useSelector(
    (state: any) => state.abilityEdge
  ).abilites_edge;
  const dispatch = useDispatch();

  useEffect(() => {
    fetchDataGraph();
    handlePageChage(page);
  }, [dispatch]);

  const handlePageChage = (newPage: number) => {
    setPage(newPage);
    const param = {
      limit: 5,
      offset: newPage * 5,
    };
    dispatch(getAbilityEdge(param));
  };

  const deleteFunction = () => {
    dispatch(
      deleteAbilityEdge(selectedRelation.id, { limit: 5, offset: 1 }, () => {
        fetchDataGraph();
        setOpenModal(false);
        setSnackBar({
          message: "Relacionamento Excluido",
          open: true,
          type: "success",
        });
      })
    );

    setSelectedRelation(undefined);
  };

  const fetchDataGraph = () => {
    setAbilitiesDataGraphFiltered(undefined);
    KnowledegesServices.getAllKnowledgesGraph(1000, 0).then((response: any) => {
      setKnowledges(response.data);
    });
    AbilityEdgeService.fetchAbilitEdge({ limit: 1000, offset: 0 }).then(
      (response: any) => {
        setAbilitiesDataGraph(response.data);
        setAbilitiesDataGraphFiltered(response.data);
      }
    );
  };

  const isRelationshipExists = (id_ab1: number, id_ab2: number): boolean => {
    const search: object | undefined = abilitiesDataGraphFiltered?.results.find(
      (rel) => {
        return (
          (rel.from_ability.id === id_ab1 && rel.to_ability.id === id_ab2) ||
          (rel.from_ability.id === id_ab2 && rel.to_ability.id === id_ab1)
        );
      }
    );
    return search !== undefined;
  };

  const { mutate: mutateAbility } = useMutation(
    (dataToSave: Partial<Ability>) => {
      if (
        isRelationshipExists(
          dataToSave.parent as number,
          dataToSave.id as number
        )
      ) {
        throw new Error("Relacionamento já Cadastrado.");
      }

      const abilityEdge = {
        to_ability: dataToSave.id as number,
        from_ability: dataToSave.parent as number,
      };

      return AbilityEdgeService.registerAbilitEdge(abilityEdge).then(
        (response) => {
          handlePageChage(page);
        }
      );
    },
    {
      onSuccess: () => {
        setSnackBar({
          message: "Relacionamento cadastrado",
          open: true,
          type: "success",
        });
        clearFunctions.forEach((clearFunction) => {
          clearFunction();
        });
        setClearFunctions([]);
        fetchDataGraph();
      },
      onError: (err: Error) => {
        setSnackBar({
          message: err.message,
          open: true,
          type: "error",
        });
      },
    }
  );

  const handleUpdateAbility = ({ abilityId, name }: onSelectAbilityEvent) => {
    setData({ ...data, [name]: abilityId });
    //condicional que torna a renderização de gráfico dinâmica
    //caso seja necessário fazer a exibição dinâmica dos dados basta decomentar a linha abaixo
    //if (name === "ability_origin") changeDataGraph(abilityId);
  };

  const changeDataGraph = (id: number) => {
    if (abilitiesDataGraph) {
      const fulldata: Page<AbilityEdge> =
        abilitiesDataGraph as unknown as Page<AbilityEdge>;

      const filteredAbilities: AbilityEdge[] = fulldata.results.filter(
        (ab: AbilityEdge) => {
          return (ab.from_ability.id as number) === id;
        }
      );

      let newState = { ...fulldata, results: filteredAbilities };
      setAbilitiesDataGraphFiltered(newState);
    }
  };

  // useEffect(() => {
  //   if (knowledges && abilitiesDataGraphFiltered) {
  //     console.log(JSON.stringify(knowledges));
  //     console.log(JSON.stringify(abilitiesDataGraphFiltered));
  //   }
  // }, [knowledges, abilitiesDataGraphFiltered]);

  return (
    <CardBase
      showActionButtons={false}
      title="Relação entre Habilidades"
      dataForm={undefined}
    >
      <>
        <Grid container spacing={1} paddingTop={1}>
          <Grid item xs={12}>
            <Typography>Habilidade Pai</Typography>
          </Grid>
          <AbilitySelector
            name="ability_origin"
            onSelectAbility={handleUpdateAbility}
            toClear={(clear) => {
              setClearFunctions((state) => {
                state.push(clear);
                return [...state];
              });
            }}
          />
          <Grid item xs={12}>
            <Typography>Habilidade Filha</Typography>
          </Grid>
          <AbilitySelector
            name="ability_destination"
            onSelectAbility={handleUpdateAbility}
            toClear={(clear) => {
              setClearFunctions((state) => {
                state.push(clear);
                return [...state];
              });
            }}
          />

          <Grid xs={12} padding={1}>
            <Button
              sx={{ paddingTop: 1 }}
              variant="contained"
              onClick={() => {
                if (!data["ability_origin"] || !data["ability_destination"])
                  return;

                mutateAbility({
                  id: data["ability_destination"] as number,
                  parent: data["ability_origin"] as number,
                });
              }}
            >
              Salvar Relação
            </Button>
          </Grid>

          <Grid xs={4}>
            {abilitiesDataGraphFiltered && selectedRelation ? (
              <TableContainer sx={{ maxWidth: "100%" }} component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Habilidade Principal</TableCell>
                      <TableCell>Habilidade Relacionada</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{selectedRelation.from.name}</TableCell>
                      <TableCell>{selectedRelation.to.name}</TableCell>
                      <TableCell>
                        <Tooltip title="Excluir Relacionamento">
                          <Button
                            variant="outlined"
                            sx={{
                              background: "white",
                              color: "#1D2432",
                              borderColor: "#CACDD5",
                            }}
                            onClick={() => {
                              setOpenModal(true);
                            }}
                          >
                            <Delete />
                          </Button>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}
          </Grid>

          <Grid xs={8}>
            {abilitiesDataGraphFiltered && knowledges ? (
              <Box
                sx={{
                  width: "100%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <Graph
                  dataKnowledges={knowledges}
                  dataAbilities_edge={abilitiesDataGraphFiltered}
                  width={400}
                  height={400}
                  clickFunction={setSelectedRelation}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  width: "40%",
                  height: "100%",
                  marginTop: "15%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "auto",
                }}
              >
                <CircularProgress size={200} />
              </Box>
            )}
          </Grid>
        </Grid>
        {/**
        <Box>
          <div style={{ width: "100%" }}>
            <DataGrid
              sx={{ width: "100%" }}
              columns={buildTableReader((param) => {
                const pageParam = {
                  limit: 5,
                  offset: page * 5,
                };
                dispatch(
                  deleteAbilityEdge(param.row.id, pageParam, fetchDataGraph)
                );
              })}
              rows={getDataFromPage(pageAbilities)}
              rowsPerPageOptions={[5]}
              pageSize={5}
              page={page}
              paginationMode="server"
              pagination
              disableColumnMenu
              autoHeight
              onPageChange={(e) => handlePageChage(e)}
              rowCount={pageAbilities?.count}
            />
          </div>
        </Box> */}

        <Snackbar
          open={snackBar.open}
          onClose={() => {
            setSnackBar(defaultSnackbar);
          }}
          autoHideDuration={6000}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            onClose={() => {
              setSnackBar(defaultSnackbar);
            }}
            severity={snackBar.type}
            sx={{ width: "100%" }}
          >
            {snackBar.message}
          </Alert>
        </Snackbar>

        <AbilityRelationDialog
          open={openModal}
          cancelAction={() => setOpenModal(false)}
          action={deleteFunction}
        />
      </>
    </CardBase>
  );
};
