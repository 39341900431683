import React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import NotFound from "pages/404";
import Admin from "pages/Admin";
import { useSelector } from "react-redux";
import Forbiden from "pages/Forbiden";
import AdminEditor from "pages/Admin/indexEditor";

const OuterRoutes: React.FC = () => {
  const { userData } = useSelector((state: any) => state.authentication);
  const location = useLocation();
  const url = new URLSearchParams(location.search);

  return (
    <>
      {userData.type === "student" ? (
        <Switch>
          <Route path="/" component={Forbiden} />
        </Switch>
      ) : (
        <Switch>
          <Route path="/" exact>
            <Redirect
              to={{
                pathname: "editor",
                state: {
                  redirectTo: url.get("to") ? url.get("to") : "/",
                },
              }}
            />
          </Route>
          <Route path="/admin" exact>
            <Redirect to="/editor" />
          </Route>
          <Route path="/admin" component={Admin} />
          <Route path="/editor" component={AdminEditor} />
          <Route path="*" component={NotFound} />
        </Switch>
      )}
    </>
  );
};

export default OuterRoutes;
