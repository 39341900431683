import { TaskSubContent } from "services/admin/Interfaces/Types";
import api from "services/api";

export default class TaskSubContentService {
  public static async find() {
    return api.get(`/items/tasksubcontents/`);
  }

  public static async findByid(id: number) {
    return api.get(`/items/tasksubcontents/${id}`);
  }

  public static async create(subContent: TaskSubContent) {
    return api.post("/items/tasksubcontents/", subContent);
  }

  public static async update(subContent: TaskSubContent) {
    return api.put(`/items/tasksubcontents/${subContent.id}/`, subContent);
  }

  public static async updateAndWaitReponse(subContent: TaskSubContent) {
    return await api.put(
      `/items/tasksubcontents/${subContent.id}/`,
      subContent
    );
  }

  public static async delete(id: number) {
    return api.delete(`/items/tasksubcontents/${id}/`);
  }
}
