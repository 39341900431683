import {
  ElementTypes,
  IReorderItemRequest,
  IReorderRequest,
  Item,
  SelectedElement,
  Task,
} from "services/admin/Interfaces/Types";
import { resetClickedElements } from "../../Actions/treeView/actions";

import { getTasks } from "../Tasks";
import ItensServices from "./itens.service";
import ContentServices from "../content/contents.service";
import { toast } from "react-toastify";
import { fetchContent } from "../content";
import { findExams } from "../Exam";
import { number } from "zod";

export const registerItem = (
  props: Item,
  elementSelected?: SelectedElement
) => {
  return (dispatch: any) => {
    ItensServices.registerItem(props)
      .then(({ data }: any) => {
        if (elementSelected?.elementType === ElementTypes.CONTENT) {
          if (elementSelected.element?.id) {
            ContentServices.register({
              subcontent_type: "item",
              parent: elementSelected.element?.id,
              object: data.id as number,
              position: props.number as number,
            }).then(({ data }: any) => {
              if (data.id) {
                toast.success("Item Cadastrado e vinculado com sucesso!");

                dispatch(fetchContent());
              }
            });
          } else {
            toast.warning(
              "Realizado o cadastro do item, porém não foi realizado a vinculação com o conteúdo"
            );
          }
        } else {
          dispatch(resetClickedElements());
          dispatch(getTasks());
        }
      })
      .catch((e) => {
        if (e.response.data.task) {
          if (String(e.response.data.task[0]).includes("Invalid pk")) {
            toast.error("Necessário informar uma tarefa válida");
          }
        } else {
          toast.error("Ocorreu um erro durante a criação do item");
        }
      });
  };
};

export const processItemUpdate = (
  props: Item,
  elementSelected?: SelectedElement
) => {
  return (dispatch: any) => {
    ItensServices.updateItem(props)
      .then(({ data }: any) => {
        if (data.id) {
          if (elementSelected?.elementType === ElementTypes.CONTENT) {
            dispatch(fetchContent());
          } else {
            dispatch(resetClickedElements());
            dispatch(getTasks());
          }

          toast.success("Item atualizado com sucesso!");
        } else {
          toast.error("Ocorreu um erro durante a atualização do item!");
        }
      })
      .catch(() =>
        toast.error("Ocorreu um erro durante a atualização do item!")
      );
  };
};

export const processItemDelete = (
  props: Item,
  successCallBack: any,
  errorCallBack
) => {
  return (dispatch: any) => {
    ItensServices.deleteItem(props)
      .then(({ data }: any) => {
        dispatch(resetClickedElements());
        dispatch(getTasks());
        successCallBack();
      })
      .catch(() => errorCallBack());
  };
};

export const moveItemInsideTask = (props: IReorderRequest) => {
  return (dispatch: any) => {
    ItensServices.reorderItems(props).then(({ data }: any) => {
      dispatch(findExams());
      dispatch(getTasks());
    });
  };
};
