import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ExpandLess, ExpandMore } from "@mui/icons-material";

import { Box, Typography } from "@mui/material";

import { TreeView, TreeItem } from "@mui/x-tree-view";
import {
  Assessment,
  Content,
  Exam,
  Item,
  Task,
} from "services/admin/Interfaces/Types";

import { ReactComponent as IconTask } from "assets/treeViewIcons/menu_book.svg";
import { ReactComponent as IconItem } from "assets/treeViewIcons/pencil.svg";
import { ReactComponent as Prancheta } from "assets/treeViewIcons/assignment.svg";
import { ReactComponent as Aplicacao } from "assets/treeViewIcons/bookmark.svg";

import { ReactComponent as MainAvaliacao } from "assets/treeViewIcons/main_avaliacao.svg";

import { ReactComponent as Enunciado } from "assets/treeViewIcons/enunciado.svg";

import TreeItemLabel from "./TreeItemLabel";

import { orderArrayById } from "utils/orderArrayById";

import {
  getExamsFromAssessment,
  getTasksFromExam,
} from "../utils/elementGetters";

import {
  generateTaskContent,
  proccesAssessmentSelectionUtil,
  proccesContentSelectionUtil,
  proccesExamSelectionUtil,
  proccesItemSelectionUtil,
  proccesRootElementSelectUtil,
  proccesTaskSelectionUtil,
  proccesTaskSubcontentSelectionUtil,
} from "../utils/treeUtils";

import "./tree_element.css";
interface ITreeView {
  treeFilterString: string;
}

export default function ColapsibleTreeNav(props: ITreeView) {
  const dispatch = useDispatch();
  const [lastTermSearch, setLastTermSearch] = useState(
    undefined as string | undefined
  );

  const tasks: Task[] = useSelector((state: any) => state.admin).tasks;
  const exams: Exam[] = useSelector((state: any) => state.admin).exams;
  const assessments: Assessment[] = useSelector(
    (state: any) => state.admin
  ).assessments;

  const contents: Content[] = useSelector((state: any) => state.admin).contents;
  const [tasksOrdered, setTaskOrdered] = useState([] as Task[]);
  const [examsOrdered, setExamsOrdered] = useState([] as Exam[]);
  const [assessmentsOrdered, setAssessmentsOrdered] = useState(
    [] as Assessment[]
  );

  const [expanded, setExpanded] = useState([] as string[]);

  useEffect(() => {
    let copiaTasks: Task[] = [];
    tasks.forEach((t) => {
      const copia = { ...t };
      let itemsCopia: Item[] = [...t.items];
      itemsCopia.sort((a, b) => (a.number as number) - (b.number as number));
      copia.items = itemsCopia;
      copiaTasks.push(copia);
    });

    if (props.treeFilterString !== undefined && props.treeFilterString !== "") {
      setExpanded([
        "tasks_root",
        "provas_root",
        "aplicacao_root",
        "aula_root",
        "av_root",
      ]);
    } else {
      if (lastTermSearch !== props.treeFilterString) setExpanded([]);
    }
    setLastTermSearch(props.treeFilterString);
    setTaskOrdered(orderArrayById(copiaTasks));
    setExamsOrdered(orderArrayById(exams));
    setAssessmentsOrdered(orderArrayById(assessments));
  }, [tasks, exams, assessments, props.treeFilterString]);

  const proccesRootElementSelect = () => {
    proccesRootElementSelectUtil(dispatch);
  };

  const proccesTaskSelection = (task, examId) => {
    proccesTaskSelectionUtil(task, examId, dispatch);
  };

  const proccesTaskSubcontentSelection = (subcontent) => {
    proccesTaskSubcontentSelectionUtil(subcontent, dispatch);
  };

  const proccesItemSelection = (item) => {
    proccesItemSelectionUtil(item, dispatch);
  };

  const proccesExamSelection = (exam) => {
    proccesExamSelectionUtil(exam, tasks, dispatch);
  };

  const proccesAssessmentSelection = (assessment) => {
    proccesAssessmentSelectionUtil(assessment, dispatch);
  };

  const proccesContentSelection = (content) => {
    proccesContentSelectionUtil(content, dispatch);
  };

  const handleNodeToggle = (event, nodes) => {
    setExpanded(nodes);
  };

  const generateTasksTree = (
    tasks: Task[],
    nodePrefix: string,
    searchString: string | undefined,
    examId: number
  ) => {
    const filteredTask = searchString
      ? tasks.filter((task) =>
          task.title
            .toLocaleLowerCase()
            .includes(searchString.toLocaleLowerCase())
        )
      : tasks;

    return filteredTask.map((task) => (
      <TreeItem
        className="item_nav"
        key={task.id}
        nodeId={nodePrefix + "_task_" + task.id}
        label={
          <TreeItemLabel
            element={task}
            elemenLabel={
              <Typography sx={{ color: "#365BDC" }}>
                {task.id + " - " + task.title}
              </Typography>
            }
            icon={<Prancheta />}
            // icon={<IconTask />}
            redirectLink="/editor/create/task/"
            proccesClickFunction={(e) => proccesTaskSelection(e, examId)}
          />
        }
      >
        {generateTaskContent(task).map((content) => (
          <TreeItem
            className="item_nav"
            key={content.element.id}
            nodeId={
              nodePrefix + "_qts_" + content.type + "_" + content.element.id
            }
            label={
              <TreeItemLabel
                element={content.element}
                elemenLabel={
                  <Typography sx={{ color: "#365BDC" }}>
                    {(content.order != null ? content.order + " - " : "") +
                      content.description}
                  </Typography>
                }
                icon={content.type === "ITEM" ? <IconItem /> : <Enunciado />}
                redirectLink={content.link}
                proccesClickFunction={
                  content.type === "ITEM"
                    ? proccesItemSelection
                    : proccesTaskSubcontentSelection
                }
              />
            }
          ></TreeItem>
        ))}
      </TreeItem>
    ));
  };

  const generateExamTree = (
    exams: Exam[],
    nodePrefix: string,
    searchString: string | undefined
  ) => {
    const filteredExams = searchString
      ? exams.filter((exam) =>
          exam.description
            .toLocaleLowerCase()
            .includes(searchString.toLocaleLowerCase())
        )
      : exams;

    return filteredExams.map((exam) => (
      <TreeItem
        className="item_nav"
        key={exam.id}
        nodeId={nodePrefix + "_exam_" + exam.id}
        id={`${"exam_" + exam.id}`}
        label={
          <TreeItemLabel
            element={exam}
            elemenLabel={
              <Typography sx={{ color: "#365BDC" }}>
                {exam.id + " - " + (exam.title !== null ? exam.title : "Exame")}
              </Typography>
            }
            // icon={<Prancheta />}
            icon={<IconTask />}
            redirectLink="/editor/create/exam/"
            proccesClickFunction={proccesExamSelection}
          />
        }
      >
        {generateTasksTree(
          getTasksFromExam(exam, tasksOrdered),
          nodePrefix + exam.id,
          searchString,
          exam.id as number
        )}
      </TreeItem>
    ));
  };

  const generateAssessmentTree = (
    assesments: Assessment[],
    nodePrefix: string,
    searchString: string | undefined
  ) => {
    const filteredAssessements = searchString
      ? assessments.filter((assessment) =>
          assessment.title
            .toLocaleLowerCase()
            .includes(searchString.toLocaleLowerCase())
        )
      : assesments;

    return filteredAssessements.map((assessment) => (
      <TreeItem
        className="item_nav"
        key={assessment.id}
        nodeId={nodePrefix + "_assessment_" + assessment.id}
        label={
          <TreeItemLabel
            element={assessment}
            elemenLabel={
              <Typography sx={{ color: "#365BDC" }}>
                {assessment.id + " - " + assessment.title}
              </Typography>
            }
            icon={<Aplicacao />}
            redirectLink="/editor/create/assessment/"
            proccesClickFunction={proccesAssessmentSelection}
          />
        }
      >
        {generateExamTree(
          getExamsFromAssessment(assessment, assessment.exams, examsOrdered),
          nodePrefix + assessment.id,
          searchString
        )}
      </TreeItem>
    ));
  };

  return (
    <Box maxWidth={"100%"} maxHeight={"100%"} paddingLeft={2} paddingRight={3}>
      <TreeView
        expanded={expanded}
        onNodeToggle={handleNodeToggle}
        aria-label="rich object"
        defaultCollapseIcon={<ExpandLess />}
        defaultExpandIcon={<ExpandMore />}
        sx={{
          height: "72vh",
          overflowX: "auto",
          overflowy: "scroll",
        }}
      >
        <TreeItem
          className="root_tree_element"
          key={"av_root"}
          nodeId={"av_root"}
          label={
            <TreeItemLabel
              element={undefined}
              elemenLabel={
                <Typography sx={{ color: "var(--primary-regular)" }}>
                  Avaliações
                </Typography>
              }
              icon={<MainAvaliacao />}
              redirectLink="/editor"
              proccesClickFunction={() => proccesAssessmentSelection(undefined)}
            />
          }
        >
          {generateAssessmentTree(
            assessmentsOrdered,
            "root_assessment",
            props.treeFilterString
          )}
        </TreeItem>
      </TreeView>
    </Box>
  );
}
