import {
  ElementTypes,
  Item,
  Task,
  TaskSubContent,
} from "services/admin/Interfaces/Types";
import {
  resetClickedElements,
  setSelectedElement,
} from "store/reducers/Admin/Actions/treeView/actions";
import task_subcontent from "store/reducers/task_subcontent";
import {
  gerarAssessmentFormData,
  gerarContentFormData,
  gerarElementoSelecionavel,
  gerarExamFormData,
  gerarItemFormData,
  gerarTaskFormData,
  gerarTaskSubcontentFormData,
} from "./elementBuilders";

export interface TaskContent {
  element: Item | TaskSubContent;
  type: "ITEM" | "TASK_SUBCONTENT";
  description: string;
  order: number | undefined | null;
  link: string;
}

export const generateTaskContent = (task: Task): TaskContent[] => {
  let content: TaskContent[] = [];

  task.subcontents?.forEach((sc) =>
    content.push({
      element: sc,
      type: "TASK_SUBCONTENT",
      order: sc.position,
      description: "Subenunciado",
      link: "/editor/create/subcontent/",
    })
  );

  task.items.forEach((i) =>
    content.push({
      element: i,
      type: "ITEM",
      order: i.number,
      description: i.title,
      link: "/editor/create/item/",
    })
  );

  content.sort((a, b) => (a.order as number) - (b.order as number));
  return content;
};

export const proccesRootElementSelectUtil = (dispatch) => {
  dispatch(resetClickedElements());
};

export const proccesTaskSelectionUtil = (task, examId, dispatch) => {
  dispatch(
    setSelectedElement(
      gerarElementoSelecionavel(
        ElementTypes.TASK,
        task,
        gerarTaskFormData(task),
        examId
      )
    )
  );
};

export const proccesTaskSubcontentSelectionUtil = (subcontent, dispatch) => {
  dispatch(
    setSelectedElement(
      gerarElementoSelecionavel(
        ElementTypes.TASK_SUBCONTENT,
        subcontent,
        gerarTaskSubcontentFormData(subcontent, undefined),
        undefined
      )
    )
  );
};

export const proccesItemSelectionUtil = (item, dispatch) => {
  dispatch(
    setSelectedElement(
      gerarElementoSelecionavel(
        ElementTypes.ITEM,
        item,
        gerarItemFormData(item, undefined),
        undefined
      )
    )
  );
};

export const proccesExamSelectionUtil = (exam, tasks, dispatch) => {
  dispatch(
    setSelectedElement(
      gerarElementoSelecionavel(
        ElementTypes.EXAM,
        exam,
        gerarExamFormData(exam, tasks),
        undefined
      )
    )
  );
};

export const proccesAssessmentSelectionUtil = (assessment, dispatch) => {
  dispatch(
    setSelectedElement(
      gerarElementoSelecionavel(
        ElementTypes.ASSESSMENT,
        assessment,
        gerarAssessmentFormData(assessment),
        undefined
      )
    )
  );
};

export const proccesContentSelectionUtil = (content, dispatch) => {
  const IS_ITEM =
    content !== undefined &&
    content?.subcontent_type &&
    content.subcontent_type === "item";

  dispatch(
    setSelectedElement(
      gerarElementoSelecionavel(
        ElementTypes.CONTENT,
        content,
        IS_ITEM
          ? gerarItemFormData(content.object, undefined)
          : gerarContentFormData(content, undefined, undefined),
        undefined
      )
    )
  );
};

export function reorderItemInsideTask(
  task: Task,
  idItem: number,
  direction: "UP" | "DOWN"
): Task {
  const copyTask: Task = { ...task };
  let itensToReorder: Item[] = [...task.items];
  let item: Item | undefined = itensToReorder.find((i) => i.id === idItem);

  if (task && item) {
    const factor = direction === "UP" ? -1 : +1;

    let lastIndex = Math.max(
      ...itensToReorder.map((e) => (e.number != null ? e.number : 0))
    );

    itensToReorder = itensToReorder.map((i) => {
      let item: Item = { ...i };
      if (item.number === null) item.number = lastIndex++;
      return item;
    });

    if (
      (item.number === 0 && direction === "UP") ||
      (item.number === lastIndex && direction === "DOWN")
    ) {
      return copyTask;
    }

    const currentItemIndex = item.number;
    const targetItemIndex = (item.number as number) + factor;

    itensToReorder.forEach((element, i) => {
      if (element.id !== idItem && element.number === targetItemIndex) {
        element.number = currentItemIndex;
      }

      if (element.id === idItem) {
        element.number = targetItemIndex;
      }
    });
  }
  copyTask.items = itensToReorder;
  return copyTask;
}

export function reorderSubContentInsideTask(
  task: Task,
  idSubContent: number,
  direction: "UP" | "DOWN"
): Task {
  const copyTask: Task = { ...task };
  let subContentToReorder: TaskSubContent[] = task.subcontents
    ? [...task.subcontents]
    : [];
  let subcontent: TaskSubContent | undefined = subContentToReorder.find(
    (i) => i.id === idSubContent
  );

  if (task && subcontent) {
    const factor = direction === "UP" ? -1 : +1;

    let lastIndex = Math.max(
      ...copyTask.items.map((e) => (e.number != null ? e.number : 0))
    );

    subContentToReorder = subContentToReorder.map((i) => {
      let subcontent: TaskSubContent = { ...i };
      if (subcontent.position === null) subcontent.position = lastIndex++;
      return subcontent;
    });

    if (
      (subcontent.position === 0 && direction === "UP") ||
      (subcontent.position === lastIndex && direction === "DOWN")
    ) {
      return copyTask;
    }

    const currentItemIndex = subcontent.position;
    const targetItemIndex = (subcontent.position as number) + factor;

    subContentToReorder.forEach((element, i) => {
      if (element.id !== idSubContent && element.position === targetItemIndex) {
        element.position = currentItemIndex;
      }

      if (element.id === idSubContent) {
        element.position = targetItemIndex;
      }
    });
  }
  copyTask.subcontents = subContentToReorder;
  return copyTask;
}
