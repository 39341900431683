import { useState } from "react";

import Cookies from "js-cookie";

import { Button, IconButton, Menu } from "@mui/material";

import AppsIcon from "@mui/icons-material/Apps";

import "./styles.css";

type ItemMenuNavigation = {
  title: string;
  subtitle: string;
  title_for_menu: string;
  image_for_menu: string;
  type_user: string;
  url_redirect: string;
};

export function MenuNavigationApps() {
  const APPLICATION_TITLE = process.env.REACT_APP_APPLICATION_TITLE ?? "";

  const [menuNavigationElement, setMenuNavigationElement] =
    useState<null | HTMLElement>(null);
  const open = Boolean(menuNavigationElement);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuNavigationElement(event.currentTarget);
  };

  const handleClose = () => {
    setMenuNavigationElement(null);
  };

  function getAppsAllowedNavigate() {
    const KEY_MENU_APPS = "_cenpe_m_a";
    const KEY_COUNTER_MENU_APPS = "_cenpe_c_m_a";

    const returnApps: ItemMenuNavigation[] = [];

    let counter_apps: string | number | undefined = Cookies.get(
      KEY_COUNTER_MENU_APPS
    );

    if (!counter_apps) return [];

    try {
      counter_apps = JSON.parse(atob(counter_apps));

      counter_apps = Number(counter_apps);
    } catch (error) {
      return [];
    }

    let counter_each = 1;

    while (counter_each <= counter_apps) {
      const data = Cookies.get(`${KEY_MENU_APPS}_${counter_each}`);

      counter_each += 1;

      if (!data) continue;

      returnApps.push(...JSON.parse(atob(data)));
    }

    return returnApps;
  }

  return (
    <>
      <IconButton
        id="button-menu-navigation-apps"
        aria-controls={open ? "menu-navigation-apps" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{ width: "fit-content" }}
      >
        <AppsIcon className="menu__icon_apps" />
      </IconButton>

      <Menu
        id="menu-navigation-apps"
        anchorEl={menuNavigationElement}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "button-menu-navigation-apps",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="menu__content">
          <h6 className="menu__title">Plataformas CEnPE</h6>

          <div className="menu__items_navigate">
            {getAppsAllowedNavigate().map((acesso: ItemMenuNavigation) => {
              if (APPLICATION_TITLE && acesso.title === APPLICATION_TITLE)
                return null;

              return (
                <Button
                  key={acesso.title}
                  title={`${acesso.title}`}
                  variant="text"
                  className="menu_item_button"
                  onClick={() => {
                    handleClose();

                    if (acesso.url_redirect)
                      window.location.href = acesso.url_redirect;
                  }}
                >
                  <img
                    src={`https://midia.cenpe.ufc.br/index.php/s/${acesso.image_for_menu}/preview`}
                    alt={acesso.title_for_menu}
                  />

                  <h6 className="menu_item_button__title">
                    {acesso.title_for_menu}
                  </h6>
                </Button>
              );
            })}
          </div>
        </div>
      </Menu>
    </>
  );
}
