import { Box } from "@mui/system";

import "./admin.css";
import EditorContent from "./sections/EditorContent";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
export default function AdminEditor() {
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    const to = (location.state as any)?.redirectTo;
    const isToRediret = to && to !== location.pathname && to !== "/";
    if (isToRediret) {
      history.push(to);
    }
  }, []);
  return (
    <Box>
      <EditorContent />
    </Box>
  );
}
