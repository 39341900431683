import { CardMedia, Grid, Typography } from "@mui/material";
import EmptyState from "assets/logos/empty_state.svg";

const EmptyStatePage = (props) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textJustify: "auto",
      }}
    >
      <CardMedia
        component={"img"}
        image={EmptyState}
        sx={{
          height: "auto",
          width: "auto",
        }}
      />
      <br></br>
      <Typography
        sx={{ fontWeight: "600", fontSize: "14px", lineHeight: "21px" }}
      >
        Enquanto isso, que tal um café?
      </Typography>
      <Typography
        sx={{
          fontWeight: "400",
          fontSize: "12px",
          lineHeight: "18px",
          textAlign: "center",
          width: "30%",
        }}
      >
        Estamos a todo vapor. Para visualizar os itens que você deseja,
        selecione um deles e veja os dados.
      </Typography>
    </div>
  );
};

export default EmptyStatePage;
