import { useEffect } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom";

export default function Await() {
  const history = useHistory();
  const location = history.location;

  useEffect(() => {
    setTimeout(() => {
      history.push({ pathname: "/", search: `?to=${location.pathname}` });
    }, 1000);
  }, []);
  return (
    <Backdrop open={true}>
      <CircularProgress />
    </Backdrop>
  );
}
